var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"section panel panel_basedin",attrs:{"id":"panel_basedin"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"header_text"},[_c('div',{staticClass:"basedat"},[_vm._v("You’ll be based at")]),_c('div',{staticClass:"location"},[_vm._v(_vm._s(_vm.name))])]),_c('div',{staticClass:"image_wrapper"},[_c('div',{staticClass:"img1 campus_1"},[(_vm.img)?_c('img',{attrs:{"src":require('@/assets/img/results-day/' +
              _vm.img +
              '/' +
              _vm.img +
              '-campus-1.jpg'),"alt":`${_vm.name} image 1`}}):_vm._e()]),_c('div',{staticClass:"img2 campus_2"},[(_vm.img)?_c('img',{attrs:{"src":require('@/assets/img/results-day/' +
              _vm.img +
              '/' +
              _vm.img +
              '-campus-2.jpg'),"alt":`${_vm.name} image 2`}}):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }