import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/ScrollSmoother";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

export const homeTweens = {
  mounted() {
    // Scroll to top on mount
    window.scrollTo(0, 0);

    // --- GSAP ScrollSmoother init --- //
    const smoother = ScrollSmoother.create({
      wrapper: "#app_smooth_scroll",
      content: "#smooth-content",
      smooth: 2, // seconds it takes to "catch up" to native scroll position
      effects: true, // look for data-speed and data-lag attributes on elements
    });

    // Ensure ScrollTrigger is updated after initial scroll position reset
    smoother.scrollTop(0);
    ScrollTrigger.refresh();

    const width = window.innerWidth;

    // panel_landing
    // gsap.from(".panel_landing", {
    //   scrollTrigger: {
    //     trigger: "#panel_landing",
    //     scroller: "#app_smooth_scroll",
    //     start: "top center",
    //   },
    //     y: 60,
    //     autoAlpha: 0,
    //     duration: 1.2
    //   }
    // );
    //

    //
    const panel_landing = gsap.timeline();
    panel_landing.from(
      ".panel_landing",
      {
        y: 60,
        autoAlpha: 0,
      },
      "+0.5"
    );

    ScrollTrigger.create({
      animation: panel_landing,
      trigger: "#panel_landing",
      scroller: "#app_smooth_scroll",
      start: "top center",
      // pin: true,
      // pinSpacing: "100px",
    });

    // panel_enroll
    const panel_enroll = gsap.timeline();

    panel_enroll.from(".scroller", {
      autoAlpha: 1,
    });

    ScrollTrigger.create({
      animation: panel_enroll,
      trigger: "#panel_landing",
      scroller: "#app_smooth_scroll",
      end: "10%",
      scrub: 1,
      // pin: true,
      // pinSpacing: "100px",
    });

    // panel_congrats
    gsap.from(".congrats_panel2", {
      scrollTrigger: {
        trigger: ".panel_congrats",
        scroller: "#app_smooth_scroll",
        start: "20% center",
        // pin: true,
        // pinSpacing: "120px",
      },
      x: 20,
      stagger: 0.1,
      autoAlpha: 0,
    });

    gsap.from(".video-player", {
      scrollTrigger: {
        trigger: ".panel_congrats",
        scroller: "#app_smooth_scroll",
        start: "20% center",
        // pin: true,
        // pinSpacing: "120px",
      },
      x: 20,
      stagger: 0.4,
      autoAlpha: 0,
    });

    // panel_name
    const panel_name = gsap.timeline();
    panel_name
      .from(".name", {
        x: 20,
        autoAlpha: 0,
      })
      .from(".name_congrats", {
        scale: 1.1,
      })
      .from(
        ".youre_in",
        {
          x: -20,
          autoAlpha: 0,
        },
        "+0.4"
      );

    ScrollTrigger.create({
      animation: panel_name,
      trigger: ".panel_name",
      scroller: "#app_smooth_scroll",
      start: "30% center",
      // pin: true,
      // pinSpacing: "100px",
    });

    // panel_journey
    gsap.from(".panel_journey_text", {
      scrollTrigger: {
        trigger: ".panel_journey",
        scroller: "#app_smooth_scroll",
        start: "top center",
        // pin: true,
        // pinSpacing: "60px",
      },
      x: 20,
      stagger: 0.2,
      autoAlpha: 0,
    });

    // panel_lesson
    const panel_lesson = gsap.timeline();
    panel_lesson
      .from(".panel_lesson_study", {
        x: -60,
        autoAlpha: 0,
      })
      .from(".panel_lesson_lesson", {
        x: 60,
        autoAlpha: 0,
      });

    panel_lesson.from(
      ".panel_lesson_overview_1",
      {
        x: window.innerWidth,
      },
      "+0.2"
    );
    panel_lesson.from(
      ".panel_lesson_overview_2",
      {
        x: -window.innerWidth,
      },
      "+0.2"
    );

    ScrollTrigger.create({
      animation: panel_lesson,
      trigger: "#panel_lesson",
      scroller: "#app_smooth_scroll",
      start: "top center",
      // pin: true,
      // pinSpacing: "100px",
    });

    // panel_basedin
    const panel_basedin = gsap.timeline();
    if (width > 980) {
      panel_basedin.from(".location", {
        y: -300,
      });
      panel_basedin.from(
        ".campus_1",
        {
          x: -window.innerWidth,
        },
        "+0.2"
      );

      panel_basedin.fromTo(
        ".campus_2",
        {
          x: window.innerWidth,
        },
        {
          x: "10vw",
        },
        "+0.2"
      );
    } else {
      panel_basedin.from(".location", {
        x: window.innerWidth,
      });

      panel_basedin.from(
        ".campus_1",
        {
          x: -window.innerWidth,
        },
        "+0.2"
      );

      panel_basedin.from(
        ".campus_2",
        {
          x: window.innerWidth,
        },
        "+0.2"
      );
    }

    ScrollTrigger.create({
      animation: panel_basedin,
      trigger: "#panel_basedin",
      scroller: "#app_smooth_scroll",
      start: "top center",
      // pin: true,
      // pinSpacing: "100px",
    });

    // panel_map
    const panel_map = gsap.timeline();
    if (width < 766) {
      console.log("mobile");
      panel_map.to("#map_image", {
        scale: 2.4,
        x: -210,
        y: -250,
        duration: 2,
      });
    } else if (width > 766 && width < 1025) {
      console.log("small Desk");
      panel_map.to(
        "#map_image",
        {
          scale: 2.4,
          x: -110,
          y: -550,
          duration: 1.4,
        },
        "+0.4"
      );
    } else if (width > 1025 && width < 1600) {
      console.log("small Desk");
      panel_map.to(
        "#map_image",
        {
          scale: 2.2,
          x: -110,
          y: -750,
          duration: 1.4,
        },
        "+0.4"
      );
    } else if (width > 1600) {
      console.log("small Desk");
      panel_map.to(
        "#map_image",
        {
          scale: 2.2,
          x: -110,
          y: -550,
          duration: 1.4,
        },
        "+0.4"
      );
    }

    ScrollTrigger.create({
      animation: panel_map,
      trigger: "#panel_map",
      scroller: "#app_smooth_scroll",
      start: "top center",
      // pin: true,
      // pinSpacing: "100px",
    });

    // panel_worth_it
    const panel_worth_it = gsap.timeline();
    if (width > 760) {
      panel_worth_it.from("#pwi_h5", {
        x: -window.innerWidth,
      });

      panel_worth_it.from("#pwi_h2", {
        x: window.innerWidth,
      });

      panel_worth_it.from("#pwi_img", {
        x: window.innerWidth,
        // duration: 3,
      });

      panel_worth_it.from("#pwi_p", {
        y: 800,
      });
    } else {
      panel_worth_it.from("#pwi_h5", {
        x: -window.innerWidth,
      });

      panel_worth_it.from("#pwi_h2", {
        x: window.innerWidth,
      });

      panel_worth_it.from("#pwi_img", {
        x: window.innerWidth + 40,
      });

      panel_worth_it.from("#pwi_p", {
        y: 300,
      });
    }

    ScrollTrigger.create({
      animation: panel_worth_it,
      trigger: "#panel_worth_it",
      scroller: "#app_smooth_scroll",
      start: "top center",
      // pin: true,
      // pinSpacing: "100px",
    });

    // panel_community
    const panel_community = gsap.timeline();
    if (width > 760) {
      panel_community.from("#pc_h2_1", {
        x: window.innerWidth,
      });

      panel_community.from("#pc_h2_2", {
        x: -window.innerWidth,
      });

      panel_community.from(
        "#pc_h2_3",
        {
          x: window.innerWidth,
        },
        "-0.2"
      );

      panel_community.from("#pc_img", {
        autoAlpha: 0,
        x: -100,
      }),
        "-0.4";
    } else {
      panel_community.from("#pc_h2_1", {
        x: -window.innerWidth,
      });

      panel_community.from("#pc_h2_2", {
        x: window.innerWidth,
      });

      panel_community.from(
        "#pc_h2_3",
        {
          x: -window.innerWidth,
        },
        "-0.2"
      );

      panel_community.from("#pc_img", {
        x: window.innerWidth + 60,
      });
    }

    ScrollTrigger.create({
      animation: panel_community,
      trigger: "#panel_community",
      scroller: "#app_smooth_scroll",
      start: "top center",
      // pin: true,
      // pinSpacing: "100px",
    });

    // const listItems = document.querySelectorAll(".list_item");
    // listItems.forEach((item) => {
    //   ScrollTrigger.create({
    //     trigger: item,
    //     scroller: "#app_smooth_scroll",
    //     start: "top 60%",
    //     stagger: 0.2, // Time between the start of each animation
    //     duration: 0.6,
    //     onEnter: () => {
    //       item.classList.add("active");
    //     },
    //     onLeaveBack: () => {
    //       item.classList.remove("active");
    //     },
    //   });
    // });

    gsap.registerPlugin(ScrollTrigger);

    const listItems = document.querySelectorAll(".list_item");
    listItems.forEach((item, index) => {
      ScrollTrigger.create({
        trigger: item,
        scroller: "#app_smooth_scroll",
        start: "top 60%",
        onEnter: () => {
          gsap.to(item, { className: "list_item active", delay: index * 0.2 }); // Staggered addition of active class
        },
        onLeaveBack: () => {
          // gsap.to(item, { className: "list_item", delay: index * -0.2 });
          gsap.to(item, {
            className: "list_item",
            delay: (listItems.length - index - 1) * 0.2,
          }); // Staggered removal of active class
        },
      });
    });
  },
};
