import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import "./assets/scss/main.scss";
import "locomotive-scroll/src/locomotive-scroll.scss";

import VueConfetti from "vue-confetti";
Vue.use(VueConfetti);

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
