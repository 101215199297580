<template>
	<section class="section panel panel_journey" id="panel_journey">
		<div class="container">
			<span class="pj1 panel_journey_text">READY</span>
			<span class="pj2 panel_journey_text">TO START</span>
			<span class="pj3 panel_journey_text">YOUR</span>
			<span class="pj4 panel_journey_text">JOURNEY?</span>
		</div>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PanelJourney',
  props: {
    msg: String,
  },
  mounted() {
  },
	methods: {
  },
});
</script>