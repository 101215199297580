var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"section panel green panel_lesson",attrs:{"id":"panel_lesson"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"img1 panel_lesson_overview_1"},[(_vm.img)?_c('img',{attrs:{"src":require('@/assets/img/results-day/' +
            _vm.img +
            '/' +
            _vm.img +
            '-overview-1.jpg'),"alt":`${_vm.name} image 1`}}):_vm._e()]),_vm._m(0),_c('div',{staticClass:"lesson panel_lesson_lesson"},[_vm._v(_vm._s(_vm.name))]),_c('div',{staticClass:"img2 panel_lesson_overview_2"},[(_vm.img)?_c('img',{attrs:{"src":require('@/assets/img/results-day/' +
            _vm.img +
            '/' +
            _vm.img +
            '-overview-2.jpg'),"alt":`${_vm.name} image 2`}}):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"study panel_lesson_study"},[_vm._v(" WE THINK YOU’LL "),_c('br'),_vm._v("LOVE STUDYING ")])
}]

export { render, staticRenderFns }