import Vue from "vue";
import Vuex from "vuex";

// import router from "@/router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    hasData: false,
    name: "",
    clusterName: "",
    clusterId: "",
    campus: "",
    uspText: "",
    facilitiesBodyText: "",
    welcomePageMessageBody: "",
    videoID: "",
    locations: [
      {
        clusterName: "Allied and Public Health",
        cluster: "allied-and-public-health",
        campus: "Stratford",
        uspText:
          "Our Allied & Public Health students benefit from industry links with local and national organisations.",
        facilitiesBodyText:
          "The Centre for Clinical Education at UEL offers state-of-the-art facilities for podiatry, physiotherapy, and nursing education in London. It includes practical teaching rooms, a podiatry clinic, biomechanics lab, and a Simulation Centre with advanced training tools like 'Stan'.",
        welcomePageMessageBody:
          "Studying Allied and Public Health at Stratford.",
        videoID: "985698537",
      },
      {
        clusterName: "Architecture and Design",
        cluster: "architecture-and-design",
        campus: "Docklands",
        uspText:
          "We are diverse and award-winning, empowering students to lead. Focusing on diversity, sustainability, and innovation, we prepare students to be future industry leaders. Our goal is to inspire students to make a difference and connect them with exceptional learning and strong industry links.",
        facilitiesBodyText:
          "At our Docklands campus we offer dedicated spaces for architecture and design. They feature workshops for woodwork, metal welding, plastering, ceramics, dark room photography, Risograph printing, and cutting-edge digital fabrication tools like robotic arms, 3D scanners, laser cutters, and clay printers. We also have high-spec computer labs which support the latest architecture software.",
        welcomePageMessageBody:
          "Studying Architecture and Design at Docklands. ",
        videoID: "985698537",
      },
      {
        clusterName: "Visual Arts",
        cluster: "visual-arts",
        campus: "Docklands",
        uspText:
          "We equip our students to be adaptable, multi-skilled, and ready for long-term employment in a changing world. As a hub of creativity and innovation, we nurture designers, thinkers, and problem solvers, empowering them to shape the future with versatile skills and experimental thinking.",
        facilitiesBodyText:
          "At UEL, access specialist training in analogue and digital production, including AR, VR, and robotics. Our facilities include photographic shoot studios, a fabrication lab, robotics and electronics labs, screen printing, painting studios, and digital and analogue TV/film/animation studios.",
        welcomePageMessageBody: "Studying Visual Arts at Docklands. ",
        videoID: "985698537",
      },
      {
        clusterName: "Business and Finance",
        cluster: "business-and-finance",
        campus: "USS / Docklands",
        uspText:
          "We have strong links with local SMEs and business districts including Canary Wharf giving students the opportunity to go on business trips, seek work placements, and network with global business leaders.",
        facilitiesBodyText:
          "Our Docklands and Stratford campuses feature extensive Bloomberg training suites, the Royal Docks Centre of Sustainability, and a Tax and Accountancy clinic. Enjoy modern facilities, 24-hour library access, and vibrant learning environments.",
        welcomePageMessageBody:
          "Studying Business and Finance at USS and Docklands. ",
        videoID: "985698537",
      },
      {
        clusterName: "Computer Science and AI",
        cluster: "computer-science-and-ai",
        campus: "Docklands",
        uspText:
          "We provide hands-on, lab-based practical skills, along with accredited subject-based learning and industry qualifications from Amazon, Microsoft, and Cisco. This helps students develop the knowledge, skills, and experience that employers need.",
        facilitiesBodyText:
          "The exciting upgrades to the facilities include brand new teaching, collaboration and showcase spaces which will empower students and staff to develop new skills and achieve their maximum potential.",
        welcomePageMessageBody:
          "Studying Computer Science and AI at Docklands.",
        videoID: "985698537",
      },
      {
        clusterName: "Engineering and Built Environment",
        cluster: "engineering-and-built-environment",
        campus: "Docklands",
        uspText:
          "Gain a cross-disciplinary education in real-world engineering, diversity, and sustainability through SMART Systems. Experience excellent job placements with leading companies while exploring climate change impacts and sustainable materials. Develop your skills using state-of-the-art facilities and top-tier industry placements.",
        facilitiesBodyText:
          "Our recently refurbished mechanical workshops feature manual milling machines and lathes, our HAAS CNC machines, a plasma laser cutter, and general engineering facilities. UEL also has a living lab partnership with Siemens.  ",
        welcomePageMessageBody:
          "Studying Engineering and Built Environment at Docklands. ",
        videoID: "985698537",
      },
      {
        clusterName: "Fashion",
        cluster: "fashion",
        campus: "Docklands",
        uspText:
          "Unleash your limitless creativity. Make authentic global changes. Empowered by industry experts and partnerships, become a visionary pioneer on our extraordinary journey.",
        facilitiesBodyText:
          "Our Fashion and Textiles workshops offer facilities for printing, embroidery, pattern cutting, machining, and fabricating garments. Students can access a historic garment archive and collaborate with photography, journalism, games, and fine arts departments.",
        welcomePageMessageBody: "Studying Fashion at Docklands. ",
        videoID: "985698537",
      },
      {
        clusterName: "Global Sustainable Development",
        cluster: "global-sustainable-development",
        campus: "Docklands",
        uspText:
          "Our students secure jobs throughout the world at sector-leading organisations such as Médecins Sans Frontières, the British Red Cross, Islamic Relief Worldwide, Morgan Sindall, the UN Refugee Agency, and the World Health Organisation.",
        facilitiesBodyText:
          "Our waterfront campus in the Royal Docks offers a modern learning environment with contemporary lecture theatres, art studios, labs, a multimedia centre, a 24/7 library, SportsDock, cafés, and Student Union facilities.",
        welcomePageMessageBody:
          "Studying Global Sustainable Development at Docklands. ",
        videoID: "985698537",
      },
      {
        clusterName: "Hospitality, Tourism and Events",
        cluster: "hospitality-events-and-tourism",
        campus: "Docklands",
        uspText:
          "We have strong connections with local employers such as the Excel Centre and Stratford Olympic Park. Additionally, we have partnerships with museums, hotels, and restaurants throughout London.",
        facilitiesBodyText:
          "Our waterfront campus in the Royal Docks offers a modern learning environment with contemporary lecture theatres, art studios, labs, a multimedia centre, a 24/7 library, SportsDock, cafés, and Student Union facilities.",
        welcomePageMessageBody:
          "Studying Hospitality, Tourism and Events at Docklands. ",
        videoID: "985698537",
      },
      {
        clusterName: "Law, Policing & Justice",
        cluster: "law-policing-and-justice",
        campus: "USS",
        uspText:
          "For 45 years, we have been teaching law in Stratford, one of Europe's largest regeneration areas. Our location offers easy access to the Royal Courts of Justice, Docklands, and the City of London.",
        facilitiesBodyText:
          "At our USS campus, experience our innovative UEL Legal Advice Centre where students gain hands-on learning by offering legal assistance in contract, family, landlord/tenant, and welfare rights cases.",
        welcomePageMessageBody: "Studying Law, Policing & Justice at USS. ",
        videoID: "985698537",
      },
      {
        clusterName: "Media",
        cluster: "media",
        campus: "Docklands",
        uspText:
          "Gain real-world experience with placements at companies like News UK and Eurosport. Work alongside industry professionals who bring virtual worlds to life and craft captivating stories.",
        facilitiesBodyText:
          "You'll be able to make the most of our facilities including contemporary lecture theatres and seminar rooms, art studios and exhibition spaces, audio and visual labs and a multimedia production centre.",
        welcomePageMessageBody: "Studying Media at Docklands. ",
        videoID: "985698537",
      },
      {
        clusterName: "Bioscience",
        cluster: "bioscience",
        campus: "Stratford",
        uspText:
          "Our laboratories at our Stratford Campus are all equipped with industry-standard equipment from companies such as Eppendorf. This ensures a seamless transition into any lab-based employment upon graduation.",
        facilitiesBodyText:
          "Our multi-million-pound bioscience facilities include 8 teaching laboratories, based at our London Stratford campus. These world class facilities include a multi-purpose STEM lab with a capacity for 120 students, six dedicated research laboratories including a mammalian tissue culture lab, and a medical physiology lab.",
        welcomePageMessageBody: "Studying Bioscience at Stratford. ",
        videoID: "985698537",
      },
      {
        clusterName: "Nursing and Midwifery",
        cluster: "nursing-and-midwifery",
        campus: "Stratford",
        uspText:
          "Our Hospital & Primary Care Training Hub offers an immersive hospital environment where you can practise your skills before starting your placements and entering employment after graduation.",
        facilitiesBodyText:
          "Our state-of-the-art Hospital and Primary Care Training Hub enables students to immerse themselves in an environment that mimics real world healthcare facilities. Our hub includes simulation in critical care, hospital wards, rehabilitation equipment and even a simulated home.",
        welcomePageMessageBody: "Studying Nursing and Midwifery at Stratford. ",
        videoID: "985698537",
      },
      {
        clusterName: "Performing Arts",
        cluster: "performing-arts",
        campus: "USS",
        uspText:
          "During your course, engage in masterclasses, workshops with industry pros, and arts projects with renowned companies. Learn in top-notch studios and venues from respected practitioners in dance, music, and performance.",
        facilitiesBodyText:
          "Our dance, music, and theatre facilities in University Square Stratford feature professional studios with sprung floors, mirrors, ballet bars, high-quality lighting, HD video, and sound for teaching and performances.",
        welcomePageMessageBody: "Studying Performing Arts at USS. ",
        videoID: "985698537",
      },
      {
        clusterName: "Psychology",
        cluster: "psychology",
        campus: "Stratford",
        uspText:
          "Stand out in the job market with a strong foundation in psychology, complemented by in-demand skills in data management, analysis, and critical thinking from our Psychology degrees.",
        facilitiesBodyText:
          "Our research facilities include cutting-edge neuroimaging tools such as EEG, tCS, fNIRS, eye-tracking, and virtual reality systems. We also provide biophysical recording equipment, a library of psychological assessments, and specialized suites for data analysis, video, and audio recordings.",
        welcomePageMessageBody: "Studying Psychology at Stratford. ",
        videoID: "985698537",
      },
      {
        clusterName: "Social and Community Work",
        cluster: "social-and-community-work",
        campus: "Docklands",
        uspText:
          "At the University of East London our focus is on developing your skills to support, protect and speak up for people and communities both locally and globally.",
        facilitiesBodyText:
          "Our waterfront campus in the Royal Docks offers a modern learning environment with contemporary lecture theatres, art studios, labs, a multimedia centre, a 24/7 library, SportsDock, cafés, and Student Union facilities.",
        welcomePageMessageBody:
          "Studying Social and Community Work at Docklands.",
        videoID: "985698537",
      },
      {
        clusterName: "Sport Science",
        cluster: "sport-science",
        campus: "Docklands",
        uspText:
          "We offer a dedicated service to connect you with the best work-based learning opportunities available in London. We will support you in aligning your placements with your career goals.",
        facilitiesBodyText:
          "Our £21 million SportsDock facility includes state-of-the-art sports science laboratories, a performance analysis suite, a brain lab, and functional screening equipment.",
        welcomePageMessageBody: "Studying Sport Science at Docklands. ",
        videoID: "985698537",
      },
      {
        clusterName: "Education and Teacher Training",
        cluster: "education-and-teacher-training",
        campus: "Stratford",
        uspText:
          "We maintain strong industry partnerships with education providers across London and Essex, frequently enabling our students to secure employment within these settings.",
        facilitiesBodyText:
          "Our teaching spaces exemplify excellence in pedagogy with professional-grade equipment in all classrooms. Specialised facilities cater to practical teacher training courses like Design Technology, Science, Computing, PE, and Music.",
        welcomePageMessageBody:
          "Studying Education and Teacher Training at Stratford. ",
        videoID: "985698537",
      },
    ],
  },
  mutations: {
    StudentDataFromUrl(state, data) {
      state.hasData = true;
      state.name = data.fname;
      state.clusterId = data.cluster;
      localStorage.setItem("uelStudentName", data.fname);
      localStorage.setItem("uelStudentClusterId", data.cluster);

      for (let i = 0; i < state.locations.length; ++i) {
        if (data.cluster == state.locations[i].cluster) {
          state.campus = state.locations[i].campus;
          state.clusterName = state.locations[i].clusterName;
          state.uspText = state.locations[i].uspText;
          state.facilitiesBodyText = state.locations[i].facilitiesBodyText;
          state.welcomePageMessageBody =
            state.locations[i].welcomePageMessageBody;

          state.videoID = state.locations[i].videoID;

          localStorage.setItem("uelStudentCampus", state.locations[i].campus);
          localStorage.setItem(
            "uelStudentClusterName",
            state.locations[i].clusterName
          );
          localStorage.setItem("uelStudentUspText", state.locations[i].uspText);
          localStorage.setItem(
            "uelStudentFacilitiesBodyText",
            state.locations[i].facilitiesBodyText
          );
          localStorage.setItem(
            "uelStudentWelcomePageMessageBody",
            state.locations[i].welcomePageMessageBody
          );
          localStorage.setItem("uelStudentVideoID", state.locations[i].videoID);
        }
      }
    },
    StudentDataFromStorage(state) {
      // state.hasData = true

      const name = localStorage.getItem("uelStudentName");
      const clusterId = localStorage.getItem("uelStudentClusterId");
      const clusterName = localStorage.getItem("uelStudentClusterName");
      const campus = localStorage.getItem("uelStudentCampus");
      const uspText = localStorage.getItem("uelStudentUspText");
      const facilitiesBodyText = localStorage.getItem(
        "uelStudentFacilitiesBodyText"
      );
      const welcomePageMessageBody = localStorage.getItem(
        "uelStudentWelcomePageMessageBody"
      );
      const videoID = localStorage.getItem("uelStudentVideoID");

      if (
        name &&
        clusterId &&
        clusterName &&
        campus &&
        uspText &&
        facilitiesBodyText &&
        welcomePageMessageBody &&
        videoID
      ) {
        state.hasData = true;
        state.name = name;
        state.clusterId = clusterId;
        state.clusterName = clusterName;
        state.campus = campus;
        state.uspText = uspText;
        state.facilitiesBodyText = facilitiesBodyText;
        state.welcomePageMessageBody = welcomePageMessageBody;
        state.videoID = videoID;
      }
    },
  },
  actions: {
    setStudentDataFromUrl({ commit }, payload) {
      commit("StudentDataFromUrl", payload);
      // router.push({ path: `/` })
    },
    setStudentDataFromStorage({ commit }) {
      commit("StudentDataFromStorage");
    },
  },
  modules: {},
});
