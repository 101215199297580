<template>
  <section class="section panel panel_community" id="panel_community">
    <div class="container">
      <div class="pc_h5" id="pc_h5" style="margin-top: auto">
        You’ll become part of our
      </div>
      <h2 id="pc_h2_1">INCREDIBLE</h2>
      <h2 id="pc_h2_2">DIVERSE</h2>
      <h2 id="pc_h2_3">COMMUNITY</h2>
    </div>
    <div class="img" id="pc_img">
      <img src="@/assets/img/community.jpg" alt="Diverse Crowd of Students" />
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PanelCommunity",
  props: {
    msg: String,
  },
  mounted() {},
  methods: {},
});
</script>
