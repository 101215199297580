<template>
  <section class="section panel green panel_lesson" id="panel_lesson">
    <div class="container">
      <div class="img1 panel_lesson_overview_1">
        <img
          v-if="img"
          :src="
            require('@/assets/img/results-day/' +
              img +
              '/' +
              img +
              '-overview-1.jpg')
          "
          :alt="`${name} image 1`"
        />
      </div>

      <div class="study panel_lesson_study">
        WE THINK YOU’LL <br />LOVE STUDYING
      </div>

      <div class="lesson panel_lesson_lesson">{{ name }}</div>

      <div class="img2 panel_lesson_overview_2">
        <img
          v-if="img"
          :src="
            require('@/assets/img/results-day/' +
              img +
              '/' +
              img +
              '-overview-2.jpg')
          "
          :alt="`${name} image 2`"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PanelLesson",
  props: {
    name: String,
    img: String,
  },
  mounted() {},
  methods: {},
});
</script>
