<template>
  <section class="section panel panel_worth_it" id="panel_worth_it">
    <div class="container">
      <h2 id="pwi_h5">We promise it's</h2>
      <h3 id="pwi_h2">WORTH IT</h3>
      <div class="img" id="pwi_img">
        <img
          src="@/assets/img/worthit.jpg"
          alt="University of East London Campus's"
        />
      </div>
      <p id="pwi_p">
        East London is the youngest and most diverse region in the country. Full
        of incredible culture, nightlife, sports and parks.
      </p>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PanelWorthIt",
  props: {
    msg: String,
  },

  mounted() {},
  methods: {},
});
</script>
