<template>
  <section
    class="section panel panel_career_gaurantee"
    id="panel_career_gaurantee"
  >
    <div class="container">
      <div class="title_area">
        <div class="title_left">
          <div class="sub_title">From Education to Employment</div>
          <div class="title">
            The UEL Careers<br />
            Guarantee
          </div>
          <div class="description">
            <p class="big">Think all universities are the same? Think again.</p>
            <p>
              At the University of East London, we not only promise an
              education; we guarantee a pathway to your future.
            </p>
          </div>

          <div class="section_title">
            10 Commitments of our Careers Guarantee:
          </div>
        </div>
        <div class="title_right">
          <div class="img1">
            <img src="@/assets/img/img1.png" alt="Future Skills" />
          </div>
          <div class="img2">
            <img src="@/assets/img/img2.png" alt="Careers First" />
          </div>
        </div>
      </div>
      <div class="component_list">
        <div class="list_item" v-for="(data, k) in list" :key="k">
          <div class="list_number">{{ k + 1 }}</div>
          <div class="list_item_content" v-html="data"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PanelCareerGaurantee",
  data() {
    return {
      list: [
        "Future-Focused Education",
        "Extended Career Support",
        "Real World Experience",
        "Networking in London",
        "Work While You Study",
        "Certification",
        "Innovative Career Framework",
        "Global Career Opportunities",
        "Entrepreneurial Support",
        "Proven Success",
      ],
    };
  },
  mounted() {},
  methods: {},
});
</script>
