<template>
  <!-- SCROLL CONTAINER -->

  <div class="smooth-scroll" id="app_smooth_scroll">
    <div id="smooth-content">
      <PanelLanding></PanelLanding>
      <PanelCongrats :videoId="videoID"></PanelCongrats>
      <PanelName :name="name"></PanelName>
      <PanelJourney></PanelJourney>
      <PanelLesson :name="clusterName" :img="clusterId"></PanelLesson>
      <PanelBasedIn :name="campus" :img="clusterId"></PanelBasedIn>
      <PanelFacilities
        :name="campus"
        :img="clusterId"
        :bodyText="facilitiesBodyText"
      ></PanelFacilities>
      <PanelMap></PanelMap>
      <PanelWorthIt></PanelWorthIt>
      <PanelCommunity></PanelCommunity>
      <PanelCareer :msg="uspText"></PanelCareer>
      <PanelCareerGaurantee></PanelCareerGaurantee>
      <PanelEnroll :name1="clusterName" :name2="campus"></PanelEnroll>
    </div>
    <div
      class="scroller"
      data-scroll
      data-scroll-sticky
      data-scroll-target="#app_smooth_scroll"
    >
      <span>Scroll Down</span>
      <a
        class="ca3-scroll-down-link ca3-scroll-down-arrow"
        data-ca3_iconfont="ETmodules"
        data-ca3_icon=""
      ></a>
    </div>
  </div>

  <!--smooth scroll-->
</template>
<script>
import { homeTweens } from "../mixins/homeTweens";

import PanelLanding from "@/components/PanelLanding.vue";
import PanelCongrats from "@/components/PanelCongrats.vue";
import PanelName from "@/components/PanelName.vue";
import PanelJourney from "@/components/PanelJourney.vue";
import PanelLesson from "@/components/PanelLesson.vue";
import PanelBasedIn from "@/components/PanelBasedIn.vue";
import PanelFacilities from "@/components/PanelFacilities.vue";
import PanelMap from "@/components/PanelMap.vue";
import PanelWorthIt from "@/components/PanelWorthIt.vue";
import PanelCommunity from "@/components/PanelCommunity.vue";
import PanelCareer from "@/components/PanelCareer.vue";
import PanelCareerGaurantee from "@/components/PanelCareerGaurantee.vue";

import PanelEnroll from "@/components/PanelEnroll.vue";
import { mapState } from "vuex";

export default {
  name: "Home",
  data() {
    return {
      showVideo: false,
    };
  },
  computed: mapState([
    "hasData",
    "name",
    "clusterId",
    "clusterName",
    "campus",
    "uspText",
    "facilitiesBodyText",
    "welcomePageMessageBody",
    "videoID",
  ]),
  components: {
    PanelLanding,
    PanelCongrats,
    PanelName,
    PanelJourney,
    PanelLesson,
    PanelBasedIn,
    PanelFacilities,
    PanelMap,
    PanelWorthIt,
    PanelCommunity,
    PanelCareer,
    PanelCareerGaurantee,
    PanelEnroll,
  },
  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      if (this.$route.query.fname && this.$route.query.cluster) {
        const payload = {
          fname: this.$route.query.fname,
          cluster: this.$route.query.cluster,
        };
        this.$store.dispatch("setStudentDataFromUrl", payload);
      } else {
        this.$store.dispatch("setStudentDataFromStorage");
      }
    },
  },
  mixins: [homeTweens],
};
</script>
