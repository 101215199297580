<template>
  <section class="section panel panel_career">
    <div class="container">
      <div class="col col_left">
        <img
          class="career_panel_img"
          src="@/assets/img/career_panel.jpg"
          alt="At the end of it all you’ll be on your way to a"
        />
      </div>
      <div class="col col_right">
        <h2>At the end of it all you’ll be on your way to a</h2>
        <h3>REWARDING <br />CAREER</h3>
        <p v-html="msg"></p>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PanelCareer",
  props: {
    msg: String,
  },
});
</script>
