var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"section panel panel_facilities",attrs:{"id":"panel_facilities"}},[_c('div',{staticClass:"col_image"},[_c('div',{staticClass:"img1 facility_1"},[(_vm.img)?_c('img',{attrs:{"src":require('@/assets/img/results-day/' +
            _vm.img +
            '/' +
            _vm.img +
            '-facilities-1.jpg'),"alt":`${_vm.name} image 1`}}):_vm._e()]),_c('div',{staticClass:"img2 facility_2"},[(_vm.img)?_c('img',{attrs:{"src":require('@/assets/img/results-day/' +
            _vm.img +
            '/' +
            _vm.img +
            '-facilities-2.jpg'),"alt":`${_vm.name} image 2`}}):_vm._e()])]),_c('div',{staticClass:"col_text"},[_c('div',{staticClass:"subtitle"},[_vm._v("Be career ready with")]),_c('div',{staticClass:"title"},[_vm._v("OUR FACILITIES")]),_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.bodyText)}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }