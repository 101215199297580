<template>
  <footer class="section panel panel_enroll" id="panel_enroll">
    <div class="container">
      <h2>I'm coming to the</h2>
      <h3>University of East London</h3>
      <h4>Studying {{ name1 }} at {{ name2 }}.</h4>
      <div class="button_group">
        <a
          href="https://uel.ac.uk/start?utm_source=dynamics&utm_medium=email&utm_campaign=uk_results_2024"
          class="button_social_text"
          target="_blank"
          ttile="Start Now"
          ><span>Start Now</span></a
        >
        <div class="button_social_block">
          <p>Follow us on social media:</p>
          <a
            href="https://twitter.com/UEL_News"
            class="button_social button_social_tw"
            target="_blank"
          >
            <span>
              <svg
                width="71"
                height="73"
                viewBox="0 0 71 73"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="71"
                  height="71.3103"
                  transform="translate(0 0.845703)"
                  fill="transparent"
                />
                <path
                  d="M14.4824 16.8457L30.6981 38.5277L14.38 56.156H18.0526L32.3391 40.7221L43.8822 56.156H56.38L39.2519 33.2545L54.4407 16.8457H50.7681L37.611 31.06L26.9803 16.8457H14.4824ZM19.8832 19.5509H25.6247L50.9784 53.4504H45.2369L19.8832 19.5509Z"
                  fill="white"
                />
              </svg>
            </span>
          </a>
          <a
            href="https://www.linkedin.com/school/university-of-east-london/"
            class="button_social button_social_li"
            target="_blank"
            ><span
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="370.031"
                height="370.094"
                viewBox="0 0 370.031 370.094"
              >
                <path
                  id="linkedin_2"
                  data-name="linkedin 2"
                  class="cls-1"
                  d="M484.135,302.1q0,78.71,0,157.417a27.121,27.121,0,0,1-22.983,27.161,31.349,31.349,0,0,1-5.11.321q-157.1.022-314.2,0.009c-13.643,0-24.389-8.609-27.264-21.867a25.084,25.084,0,0,1-.481-5.288q-0.033-157.93-.016-315.861c0-13.937,9.936-25.014,23.948-26.721a44,44,0,0,1,5.3-.353q156.281-.024,312.559.014c11,0.006,19.582,4.641,25.084,14.388a25.53,25.53,0,0,1,3.15,12.747Q484.148,223.08,484.135,302.1ZM374.543,432.513h2.287c16.758,0,33.516-.032,50.274.044,1.948,0.009,2.517-.473,2.512-2.472-0.065-29.551.053-59.1-.1-88.652-0.051-9.756-.6-19.518-1.186-29.261a89.037,89.037,0,0,0-4.984-24.284c-3.8-10.786-9.785-20.052-19.468-26.538-8.321-5.574-17.639-8.26-27.5-9.441-9.277-1.111-18.506-1.241-27.661.865-12.884,2.964-23.784,9.253-32.16,19.606-1.887,2.332-3.51,4.879-5.562,7.759V255.791H258.457v176.6h54.691v-2.725c0-28.662-.051-57.323.036-85.984,0.022-7.083.43-14.168,2.287-21.077,1.9-7.069,4.937-13.449,11.21-17.694,7.836-5.3,16.619-6.109,25.662-4.691,10.523,1.65,16.547,8.281,19.476,18.231,2.242,7.617,2.642,15.444,2.675,23.264,0.122,29.345.049,58.691,0.049,88.036v2.762Zm-150.75-.121V255.8H169.058v176.6h54.735Zm4.39-232.528a31.927,31.927,0,0,0-31.653-32.058c-17.572-.051-32.075,14.236-32.107,31.629A31.881,31.881,0,1,0,228.183,199.864Z"
                  transform="translate(-114.094 -116.906)"
                /></svg></span
          ></a>
          <a
            href="https://www.instagram.com/universityofeastlondon"
            class="button_social button_social_in"
            target="_blank"
            ><span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="369.031"
                height="369"
                viewBox="0 0 369.031 369"
              >
                <path
                  id="instagram"
                  class="cls-1"
                  d="M300.138,481.947c-23.916,0-47.838.3-71.748-.091-20.233-.33-40.1-3.36-58.251-13.007-29.094-15.46-45.74-40.273-50.257-72.385-2.767-19.668-3.178-39.744-3.533-59.656-0.527-29.585-.429-59.193.1-88.779,0.354-19.754.167-39.67,5.694-58.955,11.688-40.78,39.064-64.622,80.412-71.157,19.724-3.118,40.012-3.215,60.064-3.575,29.44-.529,58.9-0.434,88.344.1,19.611,0.357,39.371.186,58.541,5.6,41.2,11.64,65.13,39.213,71.627,80.938,3.052,19.6,3.16,39.738,3.511,59.652q0.779,44.164-.105,88.353c-0.389,20.6-.2,41.354-6.408,61.4-12.251,39.532-39.465,62.414-79.7,68.715-19.732,3.09-40.015,2.848-60.068,3.627-12.724.494-25.482,0.092-38.225,0.092v-0.873ZM452.355,298.729l-0.74-.016c0-15.011.3-30.029-.086-45.03-0.382-14.7-.923-29.431-2.365-44.053-3-30.41-21.92-51.458-51.478-58.341-17.263-4.02-34.891-3.507-52.386-3.787-29.869-.48-59.755-0.38-89.628-0.028-14.693.174-29.427,0.918-44.049,2.352-30.393,2.98-51.444,21.885-58.329,51.483-4.08,17.54-3.509,35.455-3.8,53.236-0.469,29.164-.381,58.345-0.023,87.513,0.186,15.12.866,30.285,2.356,45.328,3.3,33.3,26.512,56.46,59.8,59.813a408.949,408.949,0,0,0,42.349,2.306c43.028-.152,86.062-0.558,129.077-1.588a69.791,69.791,0,0,0,40.131-13.246c18.258-13.36,25.6-32.491,26.734-54.032C451.366,353.369,451.6,326.035,452.355,298.729Zm-57.307-.136a94.555,94.555,0,1,1-94.257-94.652A94.26,94.26,0,0,1,395.048,298.593Zm-33.185.109c0.013-34.013-27.193-61.539-60.855-61.568-34.351-.031-61.859,27.072-61.881,60.968-0.021,34.3,27.122,61.763,61.069,61.78A61.149,61.149,0,0,0,361.863,298.7Zm37.092-76.623a21.6,21.6,0,0,1-22.046-21.921A22.043,22.043,0,1,1,398.955,222.079Z"
                  transform="translate(-115.969 -113.969)"
                />
              </svg> </span
          ></a>
          <a
            href="https://www.youtube.com/user/videouel"
            class="button_social button_social_yt"
            target="_blank"
            ><span
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="315.062"
                height="220.969"
                viewBox="0 0 315.062 220.969"
              >
                <path
                  id="youtube"
                  class="cls-1"
                  d="M299.1,410.981c-15.421-.26-32.235-0.432-49.041-0.863-11.678-.3-23.367-0.667-35.012-1.531-11.5-.852-23.067-1.695-34.387-3.748-17.27-3.132-27.757-14.072-31.792-31.108a174.97,174.97,0,0,1-4.208-31.709c-0.754-15.172-1.457-30.356-1.672-45.542-0.139-9.868.432-19.761,1.038-29.62,0.847-13.772,1.856-27.548,5.159-41.015,4.021-16.393,16.306-27.831,33.124-30.243,9.93-1.424,19.947-2.38,29.957-3.077,30.749-2.144,61.562-2.525,92.368-2.518,19.308,0,38.62.557,57.922,1.127,11.421,0.338,22.849.937,34.234,1.893,8.746,0.735,17.621,1.435,26.1,3.527,15.91,3.925,25.551,14.615,29.249,30.576a179.768,179.768,0,0,1,4.2,31.711c0.755,15.172,1.457,30.356,1.672,45.542,0.14,9.868-.422,19.762-1.039,29.621-0.893,14.267-1.677,28.611-5.587,42.446-4.4,15.576-14.84,25.276-30.686,28.376a232.134,232.134,0,0,1-26.358,3.132C363.106,410.412,331.791,410.512,299.1,410.981ZM269,253.166V347.7l81.739-47.267Z"
                  transform="translate(-142.969 -190)"
                /></svg></span
          ></a>
          <a
            href="https://www.tiktok.com/@universityofeastlondon?lang=en"
            class="button_social button_social_tt"
            target="_blank"
            ><span
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="374.094"
                height="437.656"
                viewBox="0 0 374.094 437.656"
              >
                <path
                  id="tiktok"
                  class="cls-1"
                  d="M484.069,189.714c0.024,6.581.069,13.161,0.066,19.742q-0.01,27.069-.053,54.14l-0.337.363c-6.074-.389-12.156-0.679-18.218-1.193a126.631,126.631,0,0,1-22.2-4.261A163.913,163.913,0,0,1,406.7,243.952c-6.841-3.769-13.407-8.037-20.295-12.2v2.167q0,75.457,0,150.917c0,1.342-.081,2.685-0.123,4.028q-0.093.246-.184,0.493a131.992,131.992,0,0,1-3.312,25.7,135.6,135.6,0,0,1-31.558,61.858c-18.281,20.638-40.7,34.8-67.431,41.956a111.658,111.658,0,0,1-25.4,4.22c-6.4.2-12.833,0.68-19.213,0.341a113.456,113.456,0,0,1-25.83-4.294q-74.982-21.93-97.78-96.706c-2.058-6.736-3.051-13.817-4.259-20.786-1.684-9.708-1.429-19.55-.762-29.3A128.256,128.256,0,0,1,116,343.519a139.337,139.337,0,0,1,41.041-64.433,134.5,134.5,0,0,1,59.966-31.072c7.115-1.65,14.5-2.142,21.777-3.074,2.209-.282,4.457-0.263,6.687-0.382l0.317,0.39v73.837l-0.316.4c-6.083.667-12.149,1.451-17.932,3.583-19.68,7.253-33.038,20.816-40.143,40.51a59.56,59.56,0,0,0-3.422,25.021c2.04,25.823,18.219,48.313,43.411,57.1a57.461,57.461,0,0,0,24.624,3.287c22.572-2.227,39.753-13.216,51.133-32.961a61.363,61.363,0,0,0,7.149-20.284,67.272,67.272,0,0,0,.988-11.458q0.078-147.656.043-295.311c0-.848.029-1.7,0.044-2.545l0.436-.216h73.407c0,1.434-.031,2.619,0,3.8,0.674,22.73,8.32,42.955,22.257,60.786a99.8,99.8,0,0,0,43.207,31.981,93.954,93.954,0,0,0,23.063,5.939c3.326,0.394,6.67.634,10.006,0.944ZM311.8,85.906l-0.436.216ZM484.069,189.714l-0.323-.355ZM245.787,244.948l-0.317-.39Zm237.958,19.011,0.337-.363ZM245.471,319.185l0.316-.4ZM386.1,389.356q0.091-.248.184-0.493Q386.187,389.109,386.1,389.356Z"
                  transform="translate(-110.031 -85.906)"
                /></svg></span
          ></a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PanelEnroll",
  props: {
    name1: String,
    name2: String,
  },
});
</script>
