<template>
  <section class="section panel panel_congrats" id="panel_congrats">
    <canvas
      id="panel_congrats_canvas"
      width="1920"
      height="579"
      style="display: block; position: absolute; pointer-events: none; top: 0px"
    >
    </canvas>
    <div class="container">
      <div class="congrats_inner">
        <div class="congrats_left">
          <div class="congrats congrats_panel2">CONGRATULATIONS</div>
          <div class="congrats congrats_panel2">CONGRATULATIONS</div>
          <div class="congrats congrats_panel2">CONGRATULATIONS</div>
          <div class="congrats congrats_panel2">CONGRATULATIONS</div>
        </div>
        <div class="congrats_right">
          <PanelVideo></PanelVideo>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import PanelVideo from "@/components/PanelVideo.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "PanelCongrats",
  props: {
    msg: String,
    videoId: {
      type: String,
      required: true,
    },
  },
  computed: {},
  data() {
    return {};
  },
  mounted() {
    setTimeout(() => this.confetti(), 2000);
  },
  components: {
    PanelVideo,
  },
  methods: {
    confetti() {
      this.$confetti.start({
        particles: [
          {
            type: "rect",
            size: 20,
            dropRate: 5,
          },
        ],
        defaultColors: [
          "#fff",
          "#F5B6CD",
          "#004C97",
          "#FF8F1C",
          "#006F44",
          "#E03C31",
          "#FFC72C",
        ],
        defaultSize: 20,
        defaultDropRate: 5,
        particlesPerFrame: 0.1,
        canvasId: "panel_congrats_canvas",
        windSpeedMax: 0,
      });
    },
  },
});
</script>
