<template>
	<section class="section panel panel_name" id="panel_name">
		<div class="container">
			<div class="congrats name_congrats">CONGRATULATIONS</div>
			<div class="name">{{name}}</div>
			<div class="youre_in">YOU’RE IN</div>
		</div>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PanelName',
  props: {
    name: String,
  },
  mounted() {
  },
	methods: {
    animations() {
    }
  },
});
</script>