<template>
  <div class="video-player" v-if="videoIdentifyer">
    <div class="video-wrapper">
      <div
        class="videoBG"
        :class="`video_${videoIdentifyer}`"
        v-if="!isPlaying"
      >
        <div class="icon" @click="play">
          <iconOverlayPlay />
        </div>
      </div>

      <vueVimeoPlayer
        ref="player"
        @ready="onReady"
        :options="options"
        :controls="false"
        :video-id="'985698537'"
        @timeupdate="getCurrentTime"
      >
      </vueVimeoPlayer>
    </div>

    <div class="video_navigation hover-pink">
      <div class="player">
        <button id="play" @click="play" v-if="!isPlaying"><iconPlay /></button>
        <button id="pause" @click="pause" v-if="isPlaying">
          <iconPause />
        </button>
      </div>

      <div class="progress-hold">
        <input
          id="video_progression"
          class="pink"
          type="range"
          v-model="currentTime"
          max="13"
          :style="`background-size: ${progressPercent}% 100%`"
          @input="seekToTime"
          @mousedown="startDragging"
          @mouseup="stopDragging"
          @mousemove="updateTimeOnDrag"
        />
      </div>
      <div class="progress-time">
        <span class="cur-time">{{ currentTime }}</span
        >/<span class="max-time">{{ videoDuration }}</span>
      </div>
      <div class="sound">
        <button
          id="sound"
          class="pink"
          v-if="playerVolume != 0"
          @click="mute()"
        >
          <iconMute />
        </button>

        <button
          id="sound"
          class="pink muted"
          v-if="playerVolume == 0"
          @click="unMute()"
        >
          <iconSound />
        </button>

        <div class="volume-hold">
          <div class="volume-bar">
            <input
              id="volume_progression"
              class="pink"
              min="0"
              max="1"
              step="0.01"
              v-model="playerVolume"
              type="range"
              @input="volumeChange"
            />
          </div>
        </div>
      </div>
      <div class="captions">
        <button class="caption" @click="subtitlesOn" v-if="!captions">
          <iconCaption />
        </button>
        <button class="caption on" @click="subtitlesOff" v-if="captions">
          <iconCaption />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { vueVimeoPlayer } from "vue-vimeo-player";

import iconCaption from "@/components/Icons/Caption.vue";
import iconMute from "@/components/Icons/Mute.vue";
import iconOverlayPlay from "@/components/Icons/OverlayPlay.vue";
import iconPause from "@/components/Icons/Pause.vue";
import iconPlay from "@/components/Icons/Play.vue";
import iconSound from "@/components/Icons/Sound.vue";
export default {
  data() {
    return {
      options: {
        muted: false,
        autoplay: false,
        height: 610,
        width: 400,
      },
      playerReady: false,
      playerVolume: 0.5,
      currentTime: 0,
      videoDuration: 0,
      isDragging: false,
      intervalId: null,
      isPlaying: false,
      captions: false,
      videoIdentifyer: 985698537,
    };
  },
  computed: {
    progressPercent() {
      return (this.currentTime / this.videoDuration) * 100;
    },
  },

  mounted() {
    setTimeout(() => {
      // const videoId = localStorage.getItem("uelStudentVideoID");
      // if (videoId) {
      //   // this.videoIdentifyer = parseFloat(videoId);
      // }
      this.getVideoDuration();
      this.subtitlesOn();
    }, 1000);
  },

  methods: {
    getCurrentTime() {
      const player = this.$refs.player.player;
      player
        .getCurrentTime()
        .then((seconds) => {
          this.currentTime = seconds.toFixed(0);
          console.log(this.currentTime);
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getVideoDuration() {
      const player = this.$refs.player.player;
      player
        .getDuration()
        .then((time) => {
          this.videoDuration = time;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    onReady() {
      this.playerReady = true;
    },
    play() {
      this.$refs.player.play();
      this.isPlaying = !this.isPlaying;
    },
    pause() {
      this.$refs.player.pause();
      this.isPlaying = !this.isPlaying;
    },
    subtitlesOn() {
      const player = this.$refs.player.player;
      player.enableTextTrack("en-GB");
      this.captions = true;
    },
    subtitlesOff() {
      const player = this.$refs.player.player;
      player.disableTextTrack();
      this.captions = false;
    },
    mute() {
      // const player = this.$refs.player.player;
      // player.setVolume(0);
      this.playerVolume = 0;
      this.volumeChange();
    },
    unMute() {
      // const player = this.$refs.player.player;
      // player.setVolume(1);
      this.playerVolume = 1;
      this.volumeChange();
    },
    volumeChange() {
      const player = this.$refs.player.player;
      player.setVolume(this.playerVolume);
    },
    updateProgress() {
      const player = this.$refs.player.player;
      player.setCurrentTime(this.currentTime);
    },

    startDragging(event) {
      this.isDragging = true;
      this.updateTimeOnDrag(event);
    },

    stopDragging() {
      this.isDragging = false;
      this.updateTimeOnDrag();
    },

    updateTimeOnDrag(event) {
      if (this.isDragging) {
        const progressBar = this.$refs.progressBar;
        const rect = progressBar.getBoundingClientRect();
        const x = event ? event.clientX : rect.left;

        const progressWidth = progressBar.clientWidth;
        const percentage = (x - rect.left) / progressWidth;
        const newTime = percentage * this.videoDuration;
        this.currentTime = newTime;
        this.updateCurrentTime();
      }
    },
    seekToTime() {
      this.updateProgress();
    },
    updateCurrentTime() {
      clearInterval(this.intervalId);

      this.intervalId = setInterval(() => {
        if (this.currentTime >= this.videoDuration) {
          clearInterval(this.intervalId);
        }
        this.currentTime++;
      }, 1000);
    },
  },
  components: {
    vueVimeoPlayer,
    iconCaption,
    iconMute,
    iconOverlayPlay,
    iconPause,
    iconPlay,
    iconSound,
  },
};
</script>
