<template>
  <section class="section panel panel_facilities" id="panel_facilities">
    <div class="col_image">
      <div class="img1 facility_1">
        <img
          v-if="img"
          :src="
            require('@/assets/img/results-day/' +
              img +
              '/' +
              img +
              '-facilities-1.jpg')
          "
          :alt="`${name} image 1`"
        />
      </div>
      <div class="img2 facility_2">
        <img
          v-if="img"
          :src="
            require('@/assets/img/results-day/' +
              img +
              '/' +
              img +
              '-facilities-2.jpg')
          "
          :alt="`${name} image 2`"
        />
      </div>
    </div>
    <div class="col_text">
      <div class="subtitle">Be career ready with</div>
      <div class="title">OUR FACILITIES</div>
      <div class="description" v-html="bodyText"></div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PanelFacilities",
  props: {
    name: String,
    img: String,
    bodyText: String,
  },

  mounted() {},
  methods: {},
});
</script>
