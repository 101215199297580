<template>
	<section class="section panel panel_map" id="panel_map">
		<div class="map_img"><img src="@/assets/img/map.png" alt="Map location of the University of East London" id="map_image">
		</div>

		<div class="container">
			<div class="title">Ready to start at the University of East London?</div>
		</div>
	</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PanelMap',
  props: {
    msg: String,
  },

  mounted() {
  },
	methods: {
  },
});
</script>